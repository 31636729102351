$(document).ready(function () {
    $('.js-example-basic-single').select2();
});

function tabTrigger(th, id) {
    $(th).parent().children().removeClass('_act')
    $('.tabTrigger').fadeOut()
    $(id).fadeIn()
    $(th).addClass('_act')
    resizePopup($('.lktwoPopup__item.active'))
}

// upload file popup invante
$('.lktwoPopup__upload input[type="file"]').on('change', function () {
    var value = this.value
    var sepIndex = value.lastIndexOf('\\')
    if (sepIndex <= -1) {
        sepIndex = value.lastIndexOf('/')
    }
    $(this).parents('.lktwoPopup__upload').addClass('exist').next().find('.lktwoPopup__uploadThumb span').text(this.value.slice(sepIndex + 1))
})

// nav lk
var minHnav = 60,
    maxHnav = $('.lktwoNav a').length * minHnav,
    nav = $('.lktwoNav'),
    navWrap = $('.lktwoNav__wrap');

$('.lktwoNav i').click(function () {
    if (nav.hasClass('_act')) {
        nav.removeClass('_act');
        navWrap.css('max-height', minHnav + 'px');
    } else {
        nav.addClass('_act');
        navWrap.css('max-height', maxHnav + 'px');
    }
});
$(document).click(function (e) {
    if (!$(e.target).closest('.lktwoNav').length) {
        nav.removeClass('_act');
        navWrap.css('max-height', minHnav + 'px');
    }
});

//popup nav
function eventPopupNavItem() {
    var idx = $(this).index();
    var parr = $(this).parents('.lktwoPopup__item');
    var cnt = parr.find('.lktwoPopup__tabItem');
    cnt.hide();
    cnt.eq(idx).show();
    if ($(window).innerHeight() < parr.height()) {
        $('.lktwoPopup').addClass('_top');
    } else {
        $('.lktwoPopup').removeClass('_top');
    }
    $('div.lktwoPopup__navItem').removeClass('_act');
    $(this).addClass('_act');
}

function lktPopupShow(id) {
    $('body').addClass('pageScrollNone');
    $('.lktwoPopup').addClass('active');
    var thPop = $(id);
    thPop.addClass('active');
    resizePopup(thPop);
}

function lktPopupClose(el) {
    el.removeClass('active');
    if ($('.lktwoPopup__item.active').length <= 0) {
        $('.lktwoPopup').removeClass('active').removeClass('_top');
        $('body').removeClass('pageScrollNone');
    }
    resizePopup($('.lktwoPopup__item.active'))
}

function resizePopup(itemActive) {
    var winH = $(window).innerHeight();
    var popH = itemActive.height();
    setTimeout(function () {
        if (winH < popH) {
            $('.lktwoPopup').addClass('_top');
        } else {
            $('.lktwoPopup').removeClass('_top');
            $('.lktwoPopup').scrollTop(0);
        }
    }, 100);
}

function eventPopupItem(parent) {
    parent = $(parent);
    parent.find('.lkp').click(function () {
        $('body').addClass('pageScrollNone');
        $('.lktwoPopup').addClass('active');
        var el = $($(this).attr('data-popup'));
        el.addClass('active');
        resizePopup(el);
    });
    parent.find('.lktwoPopup__closeBG').click(function () {
        $('body').removeClass('pageScrollNone');
        $('.lktwoPopup__item').removeClass('active');
        $('.lktwoPopup').removeClass('active').removeClass('_top');
        resizePopup($('.lktwoPopup__item.active'));
    });
    parent.find('.lktwoPopup__close, .lktwoPopup__cancel').click(function () {
        $(this).parents('.lktwoPopup__item').removeClass('active');
        if ($('.lktwoPopup__item.active').length <= 0) {
            $('.lktwoPopup').removeClass('active').removeClass('_top');
            $('body').removeClass('pageScrollNone');
        }
        resizePopup($('.lktwoPopup__item.active'));
    });
    var tabs = parent.find('.lktwoPopup__tab');
    tabs.each(function () {
        $(this).find('.lktwoPopup__tabItem').each(function (index) {
            $(this).attr('data-h', $(this).height());
            if (index != 0) {
                $(this).hide();
            }
        });
    });
    parent.find('div.lktwoPopup__navItem').click(eventPopupNavItem);
}
eventPopupItem('.lktwo');
eventPopupItem('.lktwoPopup');

function eventForm(parent) {
    parent = $(parent);
    parent.find('.jsLkDate').datepicker({ format: 'dd.mm.yyyy', language: 'ru' });
    parent.find('[required]').each(function () {
        $(this).prev('label').addClass('req');
        //     $(this).on('focus', function () {
        //         $(this).removeClass('inputInvalid');
        //     });
        //     $(this).on('focusout', function () {
        //         if ($(this).val().length <= 0) {
        //             $(this).addClass('inputInvalid');
        //         }
        //     });
    });
    imageUpload()
    simpleImgForm()
    // parent.find('[data-mask]').each(function () {
    //     $(this).on('focus', function () {
    //         $(this).removeClass('inputInvalid');
    //     });
    //     $(this).on('focusout', function () {
    //         var v = $(this).val().length;
    //         var l = $(this).attr('data-mask').length;
    //         if ( v < l && v > 0 ){
    //             $(this).addClass('inputInvalid');
    //         }
    //     });
    // });
}
eventForm('.lktwo');
eventForm('.lktwoPopup');

$('.js-picer').datepicker({
    format: 'dd.mm.yyyy',
    language: 'ru',
    startDate: '-0d'
});
$('.js-picer-default').datepicker({
    format: 'dd.mm.yyyy',
    language: 'ru'
    // startDate: '-0d'
});
//check parent and child
$('.box-label--parent').click(function () {
    var inputBL = $(this).find('input'),
        chInputBl = $(this).next('.box-label__child').find('input');
    inputBL.prop('checked') ? chInputBl.prop('checked', true) : chInputBl.prop('checked', false)
});
$('.box-label__child').click(function () {
    var parentBL = $(this).prev('.box-label--parent').find('input');
    $(this).find('input').each(function () {
        if (!$(this).prop('checked')) {
            parentBL.prop('checked', false)
            return false;
        } else parentBL.prop('checked', true)
    });
});

//table tabs
$('.lktTabTab').click(lktTabTabFunc);

function lktTabTabFunc(event) {
    var th = $(event.target),
        ul = th.parents('ul'),
        pl = ul.next('.b-plitka');
    if (!th.hasClass('act')) {
        th.addClass('act').text('Скрыть');
        ul.addClass('lktwoTabl__item--tabActive');
        pl.slideDown();
    } else {
        th.removeClass('act').text('Показать');
        ul.removeClass('lktwoTabl__item--tabActive');
        pl.slideUp();
    }
}

function chatScrollStart(el) {
    el.scrollTop(el.find('ul').height());
}

//lk chat
$('.lkChat__scroll').each(function () {
    $(this).scrollTop($(this).find('ul').height());
});

// $('.lkChat__listRoom li').click(function () {
//     if (!$(this).hasClass('--act')) {
//         $('.lkChat__listRoom').addClass('--hide');
//         $('.lkChat__listRoom li').removeClass('--act');
//         $('.lkChat__itemRoom').removeClass('--act');
//         var id = $(this).attr('data-id');
//         $(this).addClass('--act');
//         $('#' + id).addClass('--act').scrollTop($('#' + id).find('ul').height());
//     }
// });

$('.lkChat__back').click(function () {
    $('.lkChat__listRoom').removeClass('p--hide');
    $('.lkChat__listRoom li').removeClass('p--act');
    $('.lkChat__itemRoom').removeClass('p--act');
});

$(window).resize(function () {
    $('.lktwoPopup__tab .lktwoPopup__tabItem').each(function () {
        $(this).attr('data-h', $(this).height());
    });
    resizePopup($('.lktwoPopup__item.active'));
});

$(window).on('load', function () {
    if ($(this).innerWidth() < 992) {
        $('.lkChat__listRoom').removeClass('p--hide');
        $('.lkChat__listRoom li').removeClass('p--act');
        $('.lkChat__itemRoom').removeClass('p--act');
    }
});

// found popup
function showCoolPopup(strSelector){
    $(strSelector).addClass('active')
}

function hideCoolPopup(strSelector) {
    $(strSelector).removeClass('active')
}


/* *********************** */

// jQuery(function () {
//     ymaps.ready(function () {
//         var mapCenter = [55.831903, 37.411961],
//             myMap = new ymaps.Map('map', {
//                 center: mapCenter,
//                 zoom: 10,
//                 controls: []
//             });


//         // Creating a custom layout with information about the selected geo object.
//         var customBalloonContentLayout = ymaps.templateLayoutFactory.createClass([
//             '<ul class=list>',
//             // Outputting a list of all geo objects in the cycle.
//             '{% for geoObject in properties.geoObjects %}',
//             '<li><a href=# data-placemarkid="{{ geoObject.properties.placemarkId }}" class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</a></li>',
//             '{% endfor %}',
//             '</ul>'
//         ].join(''));


//         // jQuery(document).on('click', 'a.list_item', function () {
//         //     // Determining what placemark the event occurred on.
//         //     var selectedPlacemark = points[jQuery(this).data().placemarkid];
//         //     alert(selectedPlacemark.properties.get('balloonContentBody'));
//         // });

//         var clusterer = new ymaps.Clusterer({
//             clusterDisableClickZoom: true,
//             clusterOpenBalloonOnClick: true,
//             clusterBalloonPanelMaxMapArea: 0,
//             clusterBalloonMaxHeight: 200,
//             clusterBalloonContentLayout: customBalloonContentLayout
//         });


//         var points = [];

//         for (var i = 0, l = map_addresses.length; i < l; i++){

//             var index = i;
//             var thisAddress = map_addresses[index];
//             var coords;
//             var bounds;

//             ymaps.geocode(thisAddress, {
//                 results: 1
//             }).then(function (res) {
//                 // Выбираем первый результат геокодирования.
//                 var firstGeoObject = res.geoObjects.get(0);
//                     // Координаты геообъекта.
//                     coords = firstGeoObject.geometry.getCoordinates();
//                     // Область видимости геообъекта.
//                     bounds = firstGeoObject.properties.get('boundedBy');

//                 firstGeoObject.options.set('preset', 'islands#circleDotIcon', 'iconColor', '#e84d7a');

//                 // Добавляем первый найденный геообъект на карту.
//                 // myMap.geoObjects.add(firstGeoObject);
//                 // Масштабируем карту на область видимости геообъекта.
//                 myMap.setBounds(bounds, {
//                     // Проверяем наличие тайлов на данном масштабе.
//                     checkZoomRange: true
//                 });

//             });

//             var placemark = new ymaps.Placemark(coords, {
//                 balloonContentHeader: thisAddress,
//                 placemarkId: index
//             });


//             points.push(placemark);
//             clusterer.add(points);
//         }

//         console.log(points);

//         // clusterer.add(points);
//         myMap.geoObjects.add(clusterer);
//     })
// });



/* *********************** */

var owl, containerSlider;
var smMyMap, smObjectManager;
function mapYaInit() {
    var defaultOptions = {preset: 'islands#circleDotIcon', iconColor: '#e84d7a'};
    var activeOptions = {preset: 'islands#circleDotIcon',iconColor: 'red'};

    smMyMap = new ymaps.Map('map', {
        center: [55.831903, 37.411961],
        controls: ['zoomControl'],
        zoom: 10
    }, {searchControlProvider: 'yandex#search'})

    smObjectManager = new ymaps.ObjectManager({
        clusterize: true,
        gridSize: 32,
        clusterDisableClickZoom: true
    })

    smMyMap.behaviors.disable('scrollZoom');

    smObjectManager.objects.options.set(defaultOptions);
    // smObjectManager.clusters.options.set({preset: 'islands#circleDotIcon', iconColor: '#e84d7a', clusterBalloonContentLayout: 'cluster#balloonAccordionContent'});
    smObjectManager.options.set('geoObjectOpenBalloonOnClick', false);
    smObjectManager.clusters.events.add(['click'], function (e) {
        // console.log(e)
        const clusterId = e.get('objectId'),
            clusterMarks = smObjectManager.clusters.getById(clusterId).features;

        clusterMarks.forEach(function (item) {
            var lot = $('[data-point="' + item.id + '"]')
            var lot_cnt = lot.find('.cnt')
            // var lot_url = lot_cnt.attr('data-url')
            item.properties.balloonContentHeader = lot.find('.title').text()
            var balloonContent = lot_cnt.clone()
            balloonContent
                .addClass('cluster-content')
                // .append('<p><a href="' + lot_url + '">Перейти на лот</a></p>')
                .find('br').remove()

            // item.properties.balloonContent = balloonContent[0].outerHTML
            // if (!item.lazyLoadReady) {
            //     clusterMarks[0].properties.balloonContent = 'Loading...'
            //     $.ajax({
            //         url: 'https://xn--80adfeoyeh6akig5e.xn--p1ai/v1/items/' + item.id,
            //         success: function (data) {
            //             // console.log(data)
            //             const itemData = data.items[0];
            //             !item.properties && (item.properties = {});
            //             item.properties.balloonContentHeader = itemData.title;
            //             item.properties.balloonContent = setClusterContent(itemData);
            //             item.lazyLoadReady = true;
            //         }
            //     });
            // }
        })
        console.log('cluster', clusterMarks[0].id)
        $('.found-obs__slider').trigger('to.owl.carousel', clusterMarks[0].id);
    });

    smObjectManager.objects.events.add('click', function (e) {
        var id = e.get('objectId')
        console.log('object', id)
        $('.found-obs__slider').trigger('to.owl.carousel', id)
        smMyMap.balloon.close()
    });

    smObjectManager.clusters.state.events.add('change', function (e) {
        var id = e.originalEvent.target._data.activeObject.id
        console.log('cluster item', id);
        $('.found-obs__slider').trigger('to.owl.carousel', id);
    });

    smMyMap.geoObjects.add(smObjectManager);

    smMyMap.events.add('click', function onMapClick(e) {
        if (smMyMap.behaviors.isEnabled('scrollZoom')) {
            smMyMap.events.remove('click', onMapClick);
        } else {
            smMyMap.behaviors.enable(['scrollZoom']);
        }
    });

    var map_addresses_length = map_addresses.length;
    $.each(map_addresses, function (index, value) {
        var myGeocoder = ymaps.geocode(value, {
            result: 1
        });
        myGeocoder.then(function (res) {
            var firstGeoObject = res.geoObjects.get(0);
            var coor = firstGeoObject.geometry.getCoordinates();
            smObjectManager.add({
                'type': 'Feature',
                'id': index,
                'geometry': {
                    'type': 'Point',
                    'coordinates': coor
                },
                'properties': {
                    'balloonContent': 'lorem losdfsdf'
                }
            });
            if (index == 0) smMyMap.panTo(coor)
        });
    });

    containerSlider = $('.found-obs__slider').html();
    owl = $('.found-obs__slider').owlCarousel({
        items: 1,
        navText: false,
        loop: false,
        navContainer: '.found-obs__slideNav',
        dots: false,
        // autoplay: true,
        // autoplayTimeout: 1000,
        // autoplayHoverPause: true,
        onTranslated: owlcallback
    });

    function setClusterContent(item) {
        let htmlStr = '<div class="cluster-content">';
        htmlStr += '<p><a href="' + item.code + '">' + item.subTitle + '</a></p>';
        if (item.totalArea) htmlStr += '<p>Площадь:' + item.totalArea.value + '</p>';
        if (item.startingPriceLabel && item.startingPrice) {
            let filteredPrice = priceForrmat(item.startingPrice);
            htmlStr += '<p>' + item.startingPriceLabel + ': <br><b>' + filteredPrice + ' руб.</b></p>';
        }
        if (item.address) htmlStr += '<p>Адрес: ' + item.address + '</p>';

        htmlStr += '</div>';

        return htmlStr;
    }

}

function owlcallback(event) {
    setTimeout(function() {
        var idx = +$('.owl-item.active').find('.found-obs__slideItem').attr('data-point');
        var obj = smObjectManager.objects.getById(idx);
        var coor = obj.geometry.coordinates
        console.log('owl', idx)
        if (obj.properties.balloonContent != false){
            var projection = smMyMap.options.get('projection');
            var globalpx = projection.toGlobalPixels(coor, smMyMap.getZoom())
            globalpx[0] += 350
            globalpx[1] -= 200
            smMyMap.panTo(projection.fromGlobalPixels(globalpx, smMyMap.getZoom()))
        } else {
            smMyMap.panTo(coor)
        }
    }, 10);
}

function searchMap(str) {
    str = str.toLowerCase();
    $('.found-obs__slider').trigger('destroy.owl.carousel').parent().after('<div class="found-obs__slideNav"></div>');
    $('.found-obs__slider').html(containerSlider);
    $('[class *= "-placemark-overlay"]').show();
    for (var index = 0; index < map_addresses.length; index++) {
        var item = $('.found-obs__slideItem[data-point="'+index+'"]');
        var status = map_addresses[index].toLowerCase().indexOf(str);
        if(status < 0){
            $('[class *= "-placemark-overlay"]').eq(index).hide();
            item.remove();
        } else {
            smMyMap.panTo(smObjectManager.objects.getById(index).geometry.coordinates);
        }
    }
    owl = $('.found-obs__slider').owlCarousel({
        items: 1,
        navText: false,
        loop: false,
        navContainer: '.found-obs__slideNav',
        dots: false,
        // autoplay: true,
        // autoplayTimeout: 1000,
        // autoplayHoverPause: true,
        onTranslated: owlcallback,
    });
}


function priceForrmat(input) {
    input = String(input);

    let result = '',
        partArray = input.split('.');

    function filterString(string) {
        for (let i = 0; i < string.length; i++) {
            if ((string.length - i) % 3 == 0) {
                result += ' ';
            }
            result += string[i];
        }
    }

    partArray.forEach(function (partI) {
        var currentPart = partI;

        if (partArray.indexOf(partI)) result += '.';
        filterString(currentPart);
    });

    return result;
}

// $('.doc__links .link').on('click', function(e){
//     e.preventDefault();
//     var $unit = $(this).closest('.doc__links')
//     $unit.find('.link').hide();
// })
